import { default as React, useState } from "react"
import { deleteList } from "../utilities/lists"
import { downloadListAsCSV } from "../utilities/csv"

class List extends React.Component {
  constructor(props) {
    super(props)
  }

  _handleDeleteList = async e => {
    e.preventDefault()

    try {
      if (
        window.confirm(
          "Are you sure you wish to delete the list " +
            this.props.list.name +
            "?"
        )
      ) {
        await deleteList(this.props.list._id)
        window.location.reload()
      }
    } catch (e) {
      var error = await e
      alert(error)
    }
  }

  render() {
    return (
      <>
        {this.props.expanded && (
          <div className="List expanded">
            <h3
              onClick={() => {
                this.props.onExpand()
              }}
            >
              {this.props.list.name}
            </h3>
            <div className="controls">
              <a
                onClick={() => {
                  downloadListAsCSV(this.props.list)
                }}
              >
                Download
              </a>
              <a
                href="/"
                onClick={this._handleDeleteList}
                className="destructive"
              >
                Delete
              </a>
            </div>
            <div className="patrons">
              <table>
                <tbody>
                  {this.props.list.patrons.map(patron => {
                    return (
                      <tr className="patron" key={patron._id}>
                        <td className="name">{patron.name}</td>
                        <td className="phone">{prettyNumber(patron.phone)}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {!this.props.expanded && (
          <div className="List">
            <h3
              onClick={() => {
                this.props.onExpand()
              }}
            >
              {this.props.list.name}
            </h3>
            <div className="patrons"></div>
          </div>
        )}
      </>
    )
  }
}

function prettyNumber(number) {
  if (!number) {
    return ""
  }
  var areacode = number.slice(2, 5)
  var firstThree = number.slice(5, 8)
  var lastFour = number.slice(8, 12)
  return "(" + areacode + ") " + firstThree + "-" + lastFour
}

export default List
