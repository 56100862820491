import { default as React, useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import _ from "lodash"

import CSVReader from "react-csv-reader"

import { getOrganizations } from "../utilities/organizations"
import { createList, getListsByOrganizationId } from "../utilities/lists"
import List from "../components/List"

const papaparseOptions = {
  header: false,
  dynamicTyping: true,
  skipEmptyLines: true,
  transformHeader: header => header.toLowerCase().replace(/\W/g, "_"),
}

const Lists = () => {
  const [organization, setOrganization] = useState([])
  const [lists, setLists] = useState([])

  const [isUploadingNewList, setIsUploadingNewList] = useState(false)
  const [didEnterNewListName, setDidEnterNewListName] = useState(false)
  const [newList, setNewList] = useState(null)

  const [expandedList, setExpandedList] = useState(null)

  async function updateOrganizations() {
    try {
      const organizations = await getOrganizations()
      // TODO: support organization switching in nav bar?
      const firstOrganization = organizations[0]
      const organizationLists = await getListsByOrganizationId(
        firstOrganization._id
      )

      setOrganization(firstOrganization)
      setLists(organizationLists)
    } catch (err) {
      console.log(err)
      window.location = "/"
    }
  }

  useEffect(() => {
    updateOrganizations()
  }, [])

  var url =
    "https://slack.com/oauth/v2/authorize?state=" +
    organization._id +
    "&client_id=1024326829536.1011609473026&scope=channels:history,channels:read,chat:write,chat:write.customize,commands,files:read,files:write,incoming-webhook&user_scope=channels:history,groups:history"

  return (
    <Layout title="Lists">
      <div className="Lists">
        <a href={url}>
          <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          />
        </a>
        {isUploadingNewList && !didEnterNewListName && (
          <div className="new">
            <h1>What do you want to name this new list?</h1>
            <input
              type="text"
              autoFocus
              placeholder="Community List"
              value={_.get(newList, "name", "")}
              onChange={e => {
                var updated = _.cloneDeep(newList || {})
                _.set(updated, "name", e.target.value)
                setNewList(updated)
              }}
            ></input>
            <button
              disabled={_.get(newList, "name", "").trim().length === 0}
              onClick={() => {
                setDidEnterNewListName(true)
              }}
            >
              Save
            </button>
            <button
              className="secondary"
              onClick={() => {
                setIsUploadingNewList(false)
              }}
            >
              Cancel
            </button>
          </div>
        )}
        {isUploadingNewList &&
          didEnterNewListName &&
          _.get(newList, "patrons", []).length === 0 && (
            <div className="new">
              <h1>Upload a CSV file with contacts</h1>
              <p>
                The file should contain two columns (name and phone) and no
                header row.
              </p>
              <CSVReader
                cssClass="csv-reader-input"
                label=""
                onFileLoaded={(rows, info) => {
                  const patrons = []
                  for (const rowArray of rows) {
                    if (rowArray.length >= 2) {
                      patrons.push({
                        name: rowArray[0],
                        phone: rowArray[1],
                      })
                    }
                  }

                  const rowsTooShort = rows.filter(r => r.length < 2)
                  if (rowsTooShort.length > 0) {
                    alert(
                      "Some rows in this CSV did not have two columns and may have been skipped. Please upload a CSV with the name in the first column, phone in the second column."
                    )
                  }

                  var updated = _.cloneDeep(newList)
                  _.set(updated, "patrons", patrons)
                  setNewList(updated)
                }}
                onError={error => {
                  console.log(error)
                }}
                parserOptions={papaparseOptions}
              />
            </div>
          )}
        {isUploadingNewList &&
          didEnterNewListName &&
          _.get(newList, "patrons", []).length > 0 && (
            <div className="new">
              <h1>Confirm your upload</h1>
              <p>
                Your list has {newList.patrons.length} contact
                {newList.patrons.length > 1 ? "s" : ""}.<br></br>
                <br></br>
                We're going to create a new list for these contacts.
                <br></br>
                <br></br>
                <br></br>
              </p>
              <button
                onClick={async () => {
                  onUploadList(newList.name, newList.patrons)
                }}
              >
                Continue Upload
              </button>
              <button
                className="secondary"
                onClick={() => {
                  setIsUploadingNewList(false)
                  location.reload()
                }}
              >
                Cancel
              </button>
            </div>
          )}

        {!isUploadingNewList && (
          <React.Fragment>
            <h1>Lists</h1>
            <p>Upload, edit or delete lists</p>
            <div className="lists">
              {lists.map(list => {
                return (
                  <List
                    key={list._id}
                    onExpand={() => {
                      setExpandedList(
                        expandedList == list._id ? null : list._id
                      )
                      console.log("Hey")
                    }}
                    expanded={expandedList == list._id}
                    list={list}
                  />
                )
              })}
            </div>
            <button
              onClick={() => {
                setIsUploadingNewList(true)
              }}
            >
              Upload new list
            </button>
          </React.Fragment>
        )}
      </div>
    </Layout>
  )

  async function onUploadList(name, patrons) {
    try {
      var org = organization._id
      var result = await createList(patrons, name, org)
      location.reload()
    } catch (e) {
      alert(e)
    }
  }
}

export default Lists
