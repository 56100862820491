import _ from "lodash"

export function downloadListAsCSV(list) {
  const rows = getRowsFromList(list)
  makeAndDownloadCSVFromRows(rows, list.name)
}

function getRowsFromList(list) {
  const patrons = _.get(list, "patrons", [])

  const rows = patrons.map(patron => {
    return [patron.name, patron.phone]
  })

  return rows
}

function makeAndDownloadCSVFromRows(rows, filename) {
  let csvContent =
    "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n")

  var encodedUri = encodeURI(csvContent)
  var link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", `${filename}.csv`)
  document.body.appendChild(link)

  link.click()
}
